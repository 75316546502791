import React, { Fragment, useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import LocalStorageService from '../libs/LocalStorageService';
import Viewer from 'react-viewer';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// Icons
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import PreviewIcon from '@mui/icons-material/Preview';
import MapIcon from '@mui/icons-material/Map';
// Old icons
import ImageLoad from "./ImageLoad";

import PlaceholderImage from './../assets/images/placeholder.png';
import Reviewer from "./usertype/Reviewer";
import Regular from "./usertype/Regular";

const src = "https://becomedigital-api.azurewebsites.net/api/v1";

const VerificationInfo = (props) => {

  const [canPass, setCanPass] = useState(false);
  const [, forceUpdate] = useState(0);
  const [images, setImages] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [currImg, setCurrImg] = useState(0);
  const [dataIdentity, setIdentityData] = useState();
  const [token, setToken] = useState()
  const [urlBase, setUrlBase] = useState('')
  const [riesgo, setRiesgo] = useState('Sin riesgo');
  const [openedList, setOpenedList] = useState({ isOpen: true, name: "Celulares" });

  const handleOpenList = (listName = "") => {
    setOpenedList({
      isOpen: !openedList.isOpen,
      name: listName
    });
  };

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const [form, setForm] = useState({
    fullname: props.data.fullname || "",
    birth: props.data.birth || "",
    document_number: props.data.document_number || "",
    verification: {
      alteration: props.data.verification.alteration,
      face_match: props.data.verification.face_match,
      template: props.data.verification.template,
      watch_list: props.data.verification.watch_list,
    },
    error_code: props.data.error_code || "1000 - OK",
    status: props.data.status || "1"
  })

  useEffect(() => {
    const token = LocalStorageService.getAccessToken();
    const getUrl = window.location;
    const baseUrl = getUrl.protocol + "//" + getUrl.host + "/dashboard/";
    setUrlBase(baseUrl)
    setToken(token)
    const dataIdentity = JSON.parse(atob(token.split(".")[1])).identity
    setIdentityData(dataIdentity)

    for (let property in props.data.media) {

      switch (property) {
        case "frontImgUrl":
          property = "frontImg"
          break;
        case "backImgUrl":
          property = "backImg"
          break;
        case "selfiImageUrl":
          property = "selfieImg"
          break;
        default:
          break;
      }

      // Storing the ID in a variable to use single fetch
      let mediaID = dataIdentity?.is_reviewer ? props.data.id : props.id;
      fetch(`${src}/media/${mediaID}/${property}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(res => res.blob())
        .then(blob => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setImages(data => [...data, { property, src: base64data }])
          }
        });
    }

    let verifications = props.data.verification;
    for (let ver in verifications) {
      if (!verifications[ver] && verifications[ver] !== null) {
        setCanPass(true);
        forceUpdate();
      }
    }
  }, [props.data.id, props.data.media, props.data.verification, props.id])

  useEffect(() => {
    if (props.data.phone_score_validation) {
      if (props.data.phone_score_validation.phone_score >= 801 && props.data.phone_score_validation.phone_score <= 1000) {
        setRiesgo('Alto')
      }
      if (props.data.phone_score_validation.phone_score >= 201 && props.data.phone_score_validation.phone_score <= 400) {
        setRiesgo('Medio-Bajo')
      }      
      if (props.data.phone_score_validation.phone_score >= 401 && props.data.phone_score_validation.phone_score <= 600){
        setRiesgo('Medio')
      } 
      if (props.data.phone_score_validation.phone_score >= 201 && props.data.phone_score_validation.phone_score <= 400) {
        setRiesgo('Medio-Bajo')
      }
      if (props.data.phone_score_validation.phone_score >= 51 && props.data.phone_score_validation.phone_score <= 200) {
        setRiesgo('Bajo')
      }
      if (props.data.phone_score_validation.phone_score >= 0 && props.data.phone_score_validation.phone_score <= 50) {
        setRiesgo('Alto')
      }
    }
  }, [props.data.phone_score_validation])

  const debounce = (func, wait) => {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        func.apply(context, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const printToPdf = (e, id) => {
    e.preventDefault()
    const space = document.getElementById(id);
    html2pdf()
      .set({
        margin: 0,
        filename: 'download.pdf',
        image: {
          type: 'jpeg',
          quality: 1
        },
        html2canvas: {
          scale: 3,
          letterRendering: true,
          backgroundColor: "#f8f8f8"
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait"
        }
      })
      .from(space)
      .save()
      .catch(err => console.log(err))
  };

  const passVerification = () => {
    fetch(`${src}/identity/${props.id}`, {
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    }).then(res => {
      window.location.reload();
    })
  };

  const openImgsViewer = (e) => {
    e.preventDefault();
    let index = e.target.getAttribute('data-index');
    console.log(index);
    setCurrImg(Number(index));
    setIsOpen(true);
  }

  const changeSelectValue = (e) => {
    const { name, value } = e.target;
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleChange = (text, id) => {
    console.log(text);
    debounce(setForm(prevState => ({
      ...prevState,
      [id]: text
    })), 300)
  }

  const handleChangeCheckBox = (e, id) => {
    console.log(e);
    setForm(prevState => ({
      ...prevState,
      verification: {
        ...prevState.verification,
        [id]: !form.verification[id]
      }
    }));
  }

  const handleChangeCheckBoxFromNull = (e, id) => {
    console.log(e);
    setForm(prevState => ({
      ...prevState,
      verification: {
        ...prevState.verification,
        [id]: true
      }
    }));
  }

  const submitForm = e => {
    e.preventDefault();
    fetch(`${src}/identity/reviewer/${props.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    }).then(res => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    })
  }

  return (
    <Container id="print_to_pdf" maxWidth="xl">
      <Stack spacing={1} sx={{ marginBottom: 2 }}>
        <Card>
          <CardContent>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item xs={6}>
                <Typography component="h2" variant="h5" color="primary">Detalles</Typography>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  {canPass && dataIdentity?.is_reviewer && (
                    <Button color="secondary" variant="outlined" onClick={() => passVerification}>
                      Aprobar Manualmente
                    </Button>
                  )}
                  {(dataIdentity?.permission === null || dataIdentity?.permission === undefined || dataIdentity?.permission === 2) && (
                    <Button variant="contained" color="primary" onClick={(e) => printToPdf(e, 'print_to_pdf')}>
                      Descargar
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {dataIdentity?.is_reviewer ?
          <Reviewer
            accessToken={token}
            data={props.data}
            form={form}
            urlBase={urlBase}
            riesgo={riesgo}
            handleChange={handleChange}
            handleChangeCheckBox={handleChangeCheckBox}
            handleChangeCheckBoxFromNull={handleChangeCheckBoxFromNull}
            submitForm={submitForm}
            changeSelectValue={changeSelectValue} />
          :
          <Regular
            data={props.data}
            urlBase={urlBase}
            riesgo={riesgo} />
        }

        {(props.data?.fosyga_validation || props.data.ubica_response) && (
          <Card padding={2}>
            <CardContent>
              <Grid container>
                {props.data?.ubica_response && (
                  <Grid item xs={12} md={7}>
                    <ListSubheader variant="h1" color="primary">Ubica</ListSubheader>
                    <ListItem>
                      <ListItemText primary="Nombre completo" secondary={props.data?.ubica_response.full_name ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="N° Documento" secondary={props.data?.ubica_response.document_number ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Tipo documento" secondary={props.data?.ubica_response.document_type ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Estado documento" secondary={props.data?.ubica_response.document_status ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Fecha Emisión" secondary={props.data?.ubica_response.emission_date ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Lugar Expedición" secondary={props.data?.ubica_response.expedition_place ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Rango Edad" secondary={props.data?.ubica_response.age_range ?? "N/A"} />
                    </ListItem>
                    {props.data?.ubica_response.addreses ? (
                      <Fragment>
                        <ListItemButton onClick={() => handleOpenList("Direcciones")} >
                          <ListItemText primary="Direcciones" />
                          {openedList.isOpen && openedList.name === "Direcciones" ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openedList.isOpen && openedList.name === "Direcciones"} timeout="auto" unmountOnExit>
                          {props.data?.ubica_response.addreses.map((address, index) =>
                            <List disablePadding key={`address-${index}`}>
                              <ListSubheader variant="h1" color="primary">Direccion {index + 1}</ListSubheader>
                              <ListItem>
                                <ListItemText primary="Dirección" secondary={address.addresses ?? "N/A"} />
                                <ListItemText primary="Ciudad" secondary={address.city ?? "N/A"} />
                                <ListItemText primary="Tipo de vivienda" secondary={address.location_type ?? "N/A"} />
                                <ListItemText primary="Fecha primer Reporte" secondary={address.first_report ?? "N/A"} />
                                <ListItemText primary="Fecha ultimo reporte" secondary={address.last_report ?? "N/A"} />
                              </ListItem>
                            </List>
                          )}
                        </Collapse>
                      </Fragment>
                    ) : <ListItemText primary="Direcciones" secondary="N/A" />}

                    {props.data?.ubica_response.phones ? (
                      <Fragment>
                        <ListItemButton onClick={() => handleOpenList("Teléfonos")}>
                          <ListItemText primary="Teléfonos" />
                          {openedList.isOpen && openedList.name === "Teléfonos" ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openedList.isOpen && openedList.name === "Teléfonos"} timeout="auto" unmountOnExit>
                          {props.data?.ubica_response.phones.map((telefono, index) =>
                            <List disablePadding key={`phone-${index}`}>
                              <ListSubheader variant="h1" color="primary">Teléfono {index + 1}</ListSubheader>
                              <ListItem className="text-center">
                                <ListItemText primary="Número" secondary={telefono.prefix + telefono.phone_number ?? "N/A"} />
                                <ListItemText primary="Ciudad" secondary={telefono.city ?? "N/A"} />                                
                                <ListItemText primary="Activado" secondary={telefono.is_activate ?? "N/A"} />
                                {/* <ListItemText primary="Sector económico" secondary={telefono.economic_sector ?? "N/A"} /> */}
                                {/* <ListItemText primary="Tipo de vivienda" secondary={telefono.location_type ?? "N/A"} /> */}
                                <ListItemText primary="Fecha primer Reporte" secondary={telefono.first_report ?? "N/A"} />
                                <ListItemText primary="Fecha ultimo reporte" secondary={telefono.last_report ?? "N/A"} />
                              </ListItem>
                            </List>
                          )}
                        </Collapse>
                      </Fragment>
                    ) : <ListItemText primary="Teléfonos" secondary="N/A" />}

                    {props.data?.ubica_response.cellphones ? (
                      <Fragment>
                        <ListItemButton onClick={() => handleOpenList("Celulares")}>
                          <ListItemText primary="Celulares" />
                          {openedList.isOpen && openedList.name === "Celulares" ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openedList.isOpen && openedList.name === "Celulares"} timeout="auto" unmountOnExit>
                          {props.data?.ubica_response.cellphones.map((cellphone, index) =>
                            <List disablePadding key={`cellphone-${index}`}>
                              <ListSubheader variant="h1" color="primary">Celular {index + 1}</ListSubheader>
                              <ListItem>
                                <ListItemText primary="N° Celular" secondary={cellphone.cellphone_number ?? "N/A"} />
                                <ListItemText primary="Activado" secondary={cellphone.is_activate ?? "N/A"} />
                                {/* <ListItemText primary="Sector económico" secondary={cellphone.economic_sector ?? "N/A"} /> */}
                                <ListItemText primary="Fecha primer Reporte" secondary={cellphone.first_report ?? "N/A"} />
                                <ListItemText primary="Fecha ultimo reporte" secondary={cellphone.last_report ?? "N/A"} />
                              </ListItem>
                            </List>
                          )}
                        </Collapse>
                      </Fragment>
                    ) : <ListItemText primary="Celulares" secondary="N/A" />}

                    {props.data?.ubica_response.mails ? (
                      <Fragment>
                        <ListItemButton onClick={() => handleOpenList("Emails")}>
                          <ListItemText primary="Emails" />
                          {openedList.isOpen && openedList.name === "Emails" ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openedList.isOpen && openedList.name === "Emails"} timeout="auto" unmountOnExit>
                          {props.data?.ubica_response.mails.map((mail, index) =>
                            <List disablePadding key={`email-${index}`}>
                              <ListSubheader variant="h1" color="primary">Email {index + 1}</ListSubheader>
                              <ListItem>
                                <ListItemText primary="Email" secondary={mail.email ?? "N/A"} />
                                <ListItemText primary="Fecha primer Reporte" secondary={mail.first_report ?? "N/A"} />
                                <ListItemText primary="Fecha ultimo reporte" secondary={mail.last_report ?? "N/A"} />
                              </ListItem>
                            </List>
                          )}
                        </Collapse>
                      </Fragment>
                    ) : <ListItemText primary="Emails" secondary="N/A" />}
                  </Grid>
                )}

                {props.data?.fosyga_validation && (
                  <Grid item xs={12} md={5}>
                    <ListSubheader variant="h1" color="primary">Fosyga</ListSubheader>
                    <ListItem>
                      <ListItemText primary="Estado" secondary={props.data?.fosyga_validation.estado ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Entidad" secondary={props.data?.fosyga_validation.entidad ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Afiliación" secondary={props.data?.fosyga_validation.fechaAfiliacion ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Final de afilicación" secondary={props.data?.fosyga_validation.fechaFinalAfiliacion ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Departamento" secondary={props.data?.fosyga_validation.departamento ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Ciudad" secondary={props.data?.fosyga_validation.ciudad ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Tipo de afiliado" secondary={props.data?.fosyga_validation.tipoAfiliado ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Fecha de consulta" secondary={props.data?.fosyga_validation.fechaConsulta ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Estado de validación" secondary={props.data?.fosyga_validation.validation_status ?? "N/A"} />
                    </ListItem>
                  </Grid>
                )}               
              </Grid>
            </CardContent>
          </Card>
        )}

        {props.data.media &&
          <Card padding={2}>
            <CardContent>
              <ListSubheader component="h1" color="primary" data-html2canvas-ignore>Documentos <IconButton onClick={handleIsOpen} color="secondary" aria-label="see pictures" component="span"><ZoomInIcon /></IconButton></ListSubheader>
              <Viewer
                visible={isOpen}
                onClose={handleIsOpen}
                images={images}
                activeIndex={currImg}
              />
              <Grid container spacing={2}>
                {images.map((img, index) => (
                  <Grid key={index} item xs={12} md={4} sx={{ height: '250px' }}>
                    <ImageLoad index={index} src={img.src} placeholder={PlaceholderImage} alt={img.property} action={openImgsViewer} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        }

        {props.data.comply_advantage && props.data.comply_advantage.comply_advantage_url && (
          <Card padding={2}>
            <CardContent>
              <ListSubheader color="primary" component="h1">Comply Advantage
                {props.data.comply_advantage.comply_advantage_url && <IconButton target="_blank"
                  href={props.data.comply_advantage.comply_advantage_url}
                  rel="noopener noreferrer" color="secondary" aria-label="see more" component="a"><PreviewIcon />
                </IconButton>}
              </ListSubheader>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    {props.data.comply_advantage.comply_advantage_result ? (
                      <Typography className="has-text-success">No encontrado</Typography>
                    ) : (
                      <Typography className="has-text-danger">Encontrado</Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6} textAlign="right">
                  {props.data.comply_advantage.comply_advantage_result ? (
                    <VerifiedUserIcon className="icon has-text-success" />
                  ) : (
                    <VerifiedUserIcon className="icon has-text-danger" />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {(props.data.registry || props.data.ocr_validation) && (
          <Card padding={2}>
            <CardContent>
              <Grid container spacing={2}>
                {props.data.registry && (
                  <Grid item xs={12} md={6}>
                    <List disablePadding>
                      <ListSubheader component="h1" color="primary">Registraduría</ListSubheader>
                      {props.data.registry.fullName &&
                        <ListItem>
                          <ListItemText primary="Nombre completo" secondary={props.data.registry.fullName} />
                        </ListItem>
                      }
                      {props.data.registry.issuePlace &&
                        <ListItem>
                          <ListItemText primary="Lugar de expedición" secondary={props.data.registry.issuePlace} />
                        </ListItem>
                      }
                      {props.data.registry.emissionDate &&
                        <ListItem>
                          <ListItemText primary="Fecha de Emisión" secondary={props.data.registry.emissionDate.split(" 00:")[0]} />
                        </ListItem>
                      }
                      {props.data.registry.ageRange &&
                        <ListItem>
                          <ListItemText primary="Rango de edad" secondary={props.data.registry.ageRange} />
                        </ListItem>
                      }
                      {props.data.registry.gender &&
                        <ListItem>
                          <ListItemText primary="Genero" secondary={props.data.registry.gender} />
                        </ListItem>
                      }
                    </List>
                  </Grid>
                )}

                {props.data.ocr_validation && (
                  <Grid item xs={12} md={6}>
                    <List>
                      <ListSubheader component="h1" color="primary">Validacion OCR</ListSubheader>
                      <ListItem>
                        <ListItemText primary="Sexo" secondary={props.data?.ocr_validation.sexo ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Fecha nacimiento" secondary={props.data.ocr_validation?.fecha_nacimiento ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Fecha expedición" secondary={props.data.ocr_validation?.fecha_expedicion ??
                          "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Registrador" secondary={props.data.ocr_validation?.registrador ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Primera letra" secondary={props.data.ocr_validation?.codigo_abajo?.primera_letra ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Primer número" secondary={props.data.ocr_validation?.codigo_abajo?.primer_numero ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Segundo número" secondary={props.data.ocr_validation?.codigo_abajo?.segundo_numero ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Segunda letra" secondary={props.data.ocr_validation?.codigo_abajo?.segunda_letra ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Tercer número" secondary={props.data.ocr_validation?.codigo_abajo?.tercer_numero ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Cuarto número" secondary={props.data.ocr_validation?.codigo_abajo?.cuarto_numero ?? "N/A"} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Código completo" secondary={props.data.ocr_validation?.codigo_abajo?.codigo_completo ?? "N/A"} />
                      </ListItem>
                    </List>

                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}

        {props.data.barcode && dataIdentity?.is_reviewer && (
          <Card padding={2}>
            <CardContent>
              <ListSubheader variant="h1" color="primary">Barcode</ListSubheader>
              {Object.keys(props.data.barcode).length === 0 ? <Typography> No se pudo extraer la informacion del barcode</Typography> : (
                <List>
                  {props.data.barcode.document_number &&
                    <ListItem>
                      <ListItemText primary="N° Documento" secondary={props.data.barcode.document_number} />
                    </ListItem>
                  }
                  {props.data.barcode.first_name &&
                    <ListItem>
                      <ListItemText primary="Nombres" secondary={props.data.barcode.first_name} />
                    </ListItem>
                  }
                  {props.data.barcode.last_name &&
                    <ListItem>
                      <ListItemText primary="Apellidos" secondary={props.data.barcode.last_name} />
                    </ListItem>
                  }
                  {props.data.barcode.birth_date &&
                    <ListItem>
                      <ListItemText primary="Fecha de Nacimiento" secondary={props.data.barcode.birth_date.split(" 00:")[0]} />
                    </ListItem>
                  }
                  {props.data.barcode.sex &&
                    <ListItem>
                      <ListItemText primary="Sexo" secondary={props.data.barcode.sex} />
                    </ListItem>
                  }
                  {props.data.barcode.fullname_comparison_score &&
                    <ListItem>
                      <ListItemText primary="Score Coincidencia de nombr" secondary={props.data.barcode.fullname_comparison_score} />
                    </ListItem>
                  }
                </List>
              )}
            </CardContent>
          </Card>
        )}

        {/* CHILE */}
        {props.data.chilean_registro_civil && (
          <Card padding={2}>
            <CardContent>
              <List>
                <ListSubheader component="h1" color="primary">Registro Civil Chileno</ListSubheader>
                {props.data.chilean_registro_civil.run_number &&
                  <ListItem>
                    <ListItemText primary="Número Run" secondary={props.data.chilean_registro_civil.run_number} />
                  </ListItem>
                }
              </List>
            </CardContent>
          </Card>
        )}

        {props.data.chilean_driver_license_validation && (
          <Card padding={2}>
            <CardContent>
              <List>
                <ListSubheader component="h1" color="primary">Licencia de Conducción Chilena</ListSubheader>
                {props.data.chilean_driver_license_validation.fullname &&
                  <ListItem>
                    <ListItemText primary="Nombre" secondary={props.data.chilean_driver_license_validation.fullname} />
                  </ListItem>
                }
                {props.data.chilean_driver_license_validation?.blocked &&
                  <ListItem>
                    <ListItemText primary="Bloqueado" secondary={props.data.chilean_driver_license_validation?.blocked ? "SI" : "NO"} />
                  </ListItem>
                }
              </List>

            </CardContent>
          </Card>
        )}

        {props.data.chilean_rut_validation && (
          <Card padding={2}>
            <CardContent>
              <List>
                <ListSubheader component="h1" color="primary">RUT Chileno</ListSubheader>
                {props.data.chilean_rut_validation.rut &&
                  <ListItem>
                    <ListItemText primary="RUT" secondary={props.data.chilean_rut_validation.rut} />
                  </ListItem>
                }
                {props.data.chilean_rut_validation?.fullname &&
                  <ListItem>
                    <ListItemText primary="Nombre" secondary={props.data.chilean_rut_validation?.fullname} />
                  </ListItem>
                }
                {props.data.chilean_rut_validation.active &&
                  <ListItem>
                    <ListItemText primary="Activo" secondary={props.data.chilean_rut_validation?.active ? "SI" : "NO"} />
                  </ListItem>
                }
              </List>
            </CardContent>
          </Card>
        )}
        {(props.data.ip_validation || props.data.phone_id_validation) && (
        <Card padding={2}>
          <CardContent>
            <Grid container spacing={2}>
              {props.data.ip_validation && (
                <Grid item xs={12} md={6}>
                  <List>
                    <ListSubheader component="h1" color="primary">
                      Validación de IP
                    </ListSubheader>

                    {props.data.ip_validation.country &&
                      <ListItem>
                        <ListItemText primary="Pais" secondary={props.data.ip_validation.country} />
                      </ListItem>
                    }
                    {props.data.ip_validation.region &&
                      <ListItem>
                        <ListItemText primary="Region" secondary={props.data.ip_validation.region} />
                      </ListItem>
                    }
                    {props.data.ip_validation.city &&
                      <ListItem>
                        <ListItemText primary="Ciudad" secondary={props.data.ip_validation.city} />
                      </ListItem>
                    }
                    {props.data.ip_validation.latitude &&
                      <ListItem>
                        <ListItemText primary="Latitud" secondary={props.data.ip_validation.latitude} />
                      </ListItem>
                    }
                    {props.data.ip_validation.longitude &&
                      <ListItem>
                        <ListItemText primary="Longitud" secondary={props.data.ip_validation.longitude} />
                      </ListItem>
                    }
                    {props.data.ip_validation.longitude && props.data.ip_validation.latitude &&
                      <ListItem>
                        <ListItemText secondary={<Button href={'https://maps.google.com/?q=' + props.data.ip_validation.latitude + ',' + props.data.ip_validation.longitude} color="secondary" endIcon={<MapIcon />} component="a" target="_blank" rel="noopener noreferrer">Ver mapa</Button>} />
                      </ListItem>
                    }
                  </List>
                  {props.data.ip_validation.longitude && props.data.ip_validation.latitude &&
                    <ListItem data-html2canvas-ignore>
                      <ListItemText primary={
                        <iframe title="Google Map Location" width="300"
                          height="300"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                          src={"https://maps.google.com/maps?q=" + props.data.ip_validation.latitude + "," + props.data.ip_validation.longitude + "&t=&z=11&ie=UTF8&iwloc=&output=embed"}>
                        </iframe>
                      } />
                    </ListItem>
                  }
                </Grid>
              )}

              {props.data.phone_id_validation && (
                <Grid item xs={12} md={6}>
                  <List>
                    <ListSubheader component="h1" color="primary">Validación Número de Celular</ListSubheader>
                    <ListItem>
                      <ListItemText primary="Operador" secondary={props.data.phone_id_validation?.carrier ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="SIM Swap Date" secondary={props.data.phone_id_validation?.sim_swap_date ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="SIM Swap Score" secondary={props.data.phone_id_validation.sim_swap_score ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Nombre" secondary={props.data.phone_id_validation?.contact_first_name ?? "N/A"} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Apellido" secondary={props.data.phone_id_validation?.contact_last_name ?? "N/A"} />
                    </ListItem>
                    {props.data?.phone_score_validation?.phone_score &&
                      <ListItem>
                        <ListItemText primary="Phone Score" secondary={props.data.phone_score_validation.phone_score} />
                      </ListItem>
                    }
                  </List>
                </Grid>
              )}
            </Grid>

          </CardContent>
        </Card>
        )}
        
        {props.data.runt_validation && (
          <Card padding={2}>
            <CardContent>
              <List>
                <ListSubheader component="h1" color="primary">Validación RUNT</ListSubheader>
                {props.data.runt_validation.estadoConductor &&
                  <ListItem>
                    <ListItemText primary="Estado de conductor" secondary={props.data.runt_validation.estadoConductor} />
                  </ListItem>
                }
                {props.data.runt_validation.estadoPersona &&
                  <ListItem>
                    <ListItemText primary="Estado de persona" secondary={props.data.runt_validation.estadoPersona} />
                  </ListItem>
                }
                {props.data.runt_validation.fechaInscripcion &&
                  <ListItem>
                    <ListItemText primary="Fecha de inscripción" secondary={props.data.runt_validation.fechaInscripcion} />
                  </ListItem>
                }
                {props.data.runt_validation.tieneMultas &&
                  <ListItem>
                    <ListItemText primary="Multas" secondary={props.data.runt_validation.tieneMultas} />
                  </ListItem>
                }
                {props.data.runt_validation.nroPazYSalvo &&
                  <ListItem>
                    <ListItemText primary="Paz y salvo" secondary={props.data.runt_validation.nroPazYSalvo} />
                  </ListItem>
                }

                {props.data.runt_validation.licenciasConduccion &&
                  <Fragment>
                    <ListItemButton onClick={() => handleOpenList("Licencias de conducción")}>
                      <ListItemText primary="Licencias de conducción" />
                      {openedList.isOpen && openedList.name === "Licencias de conducción" ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openedList.isOpen && openedList.name === "Licencias de conducción"} timeout="auto" unmountOnExit>
                      {props.data.runt_validation.licenciasConduccion.map(licencia =>
                        <List disablePadding>
                          <ListItem>
                            <ListItemText primary="Estado" secondary={licencia.estadoLicencia} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Fecha de expedición" secondary={licencia.fechaExpedicion} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Número de Licencia" secondary={licencia.numeroLicencia} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Oficina de Tránsito" secondary={licencia.otExpide} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Detalles" secondary={licencia.detalleLicencias.map(detalleLicencia =>
                              <List disablePadding>
                                <ListItem>
                                  <ListItemText primary="Categoría" secondary={detalleLicencia.categoria} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Fecha de expedición" secondary={detalleLicencia.fechaExpedicion} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Fecha de vencimiento" secondary={detalleLicencia.fechaVencimiento} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary="Fecha de vencimiento de examen" secondary={detalleLicencia.fechaVencimientoExamen} />
                                </ListItem>
                              </List>
                            )} />
                          </ListItem>
                        </List>
                      )}
                    </Collapse>
                  </Fragment>
                }
              </List>
            </CardContent>
          </Card>
        )}

        {props.data.userAgent && (
          <Card padding={2}>
            <CardContent>
              <List>
                <ListSubheader component="h1" color="primary">Device Fingerprint</ListSubheader>
                {props.data.userAgent.browser_major &&
                  <ListItem>
                    <ListItemText primary="Browser Major" secondary={props.data.userAgent.browser_major} />
                  </ListItem>
                }
                {props.data.userAgent.browser_name &&
                  <ListItem>
                    <ListItemText primary="Browser Name" secondary={props.data.userAgent.browser_name} />
                  </ListItem>
                }
                {props.data.userAgent.browser_version &&
                  <ListItem>
                    <ListItemText primary="Browser Version" secondary={props.data.userAgent.browser_version} />
                  </ListItem>
                }
                {props.data.userAgent.device_model &&
                  <ListItem>
                    <ListItemText primary="Device model" secondary={props.data.userAgent.device_model} />
                  </ListItem>
                }
                {props.data.userAgent.device_type &&
                  <ListItem>
                    <ListItemText primary="Device Type" sx={{ textTransform: 'uppercase' }} secondary={props.data.userAgent.device_type} />
                  </ListItem>
                }
                {props.data.userAgent.device_vendor &&
                  <ListItem>
                    <ListItemText primary="Device Vendor" secondary={props.data.userAgent.device_vendor} />
                  </ListItem>
                }
                {props.data.userAgent.os_name &&
                  <ListItem>
                    <ListItemText primary="OS Name" secondary={props.data.userAgent.os_name} />
                  </ListItem>
                }
                {props.data.userAgent.os_version &&
                  <ListItem>
                    <ListItemText primary="OS Version" secondary={props.data.userAgent.os_version} />
                  </ListItem>
                }

                {props.data.userAgent.ua &&
                  <ListItem>
                    <ListItemText primary="User Agent" secondary={props.data.userAgent.ua} />
                  </ListItem>
                }
              </List>
            </CardContent>
          </Card>
        )}
      </Stack>
    </Container>
  );
};

export default VerificationInfo;
