import React, { useEffect, useState } from 'react'
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
// Old icons
import EditIcon from '@rsuite/icons/Edit';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import SearchPeopleIcon from '@rsuite/icons/SearchPeople';
import PageIcon from '@rsuite/icons/Page';
// Form
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from "@mui/material/Button";
// Icons
import SendIcon from '@mui/icons-material/Send';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';

const src = "https://becomedigital-api.azurewebsites.net/api/v1";

const postRequest = ({ token, path, body, callback }) => {
    fetch(`${src}${path}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: body
    }).then(callback);
}

function Reviewer({ urlBase, riesgo, form, data, handleChange, handleChangeCheckBox, handleChangeCheckBoxFromNull, changeSelectValue, submitForm, accessToken }) {

    const [canUseUbicaPlus, setCanUseUbicaPlus] = useState(false);
    const [canUseTelesign, setCanUseTelesign] = useState(false);
    const [canSendForm, setCanSendForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isCompleted = data.verification.verification_status === "completed";
        if (!isCompleted) {
            setCanSendForm(true);
        }
        if (!isCompleted && !data.phone_id_validation && data.phone_number) {
            setCanUseTelesign(true)
        }
        if (!isCompleted && !data.ubica_response && data.phone_id_validation) {
            setCanUseUbicaPlus(true)
        }
    }, [data])

    const handleBureauRequest = (event) => {
        event.preventDefault();
        setIsLoading(true);
        let bodyObject = {
            document_number: data.document_number,
            contract_id: data.contract_id,
            identity_id: data.id
        }
        // if phone_number exists then add it to bodyObject
        data.phone_number && (bodyObject.cellphone_number = data.phone_number)

        postRequest({
            token: accessToken,
            path: '/ubicavalidations',
            body: JSON.stringify(bodyObject),
            callback: (res) => {
                // console.log(res)
                setTimeout(() => {
                    setIsLoading(false);
                    window.location.reload();
                }, 1000);
            }
        })
    }
    const handleTelcoRequest = (event) => {
        event.preventDefault();
        setIsLoading(true);
        let countryPrefix = data.phone_number.slice(0, 2);
        postRequest({
            token: accessToken,
            path: '/telesignvalidations',
            body: JSON.stringify({
                phone_number: `+${data.phone_number}`,
                contract_id: data.contract_id,
                phone_prefix: countryPrefix,
                identity_id: data.id
            }),
            callback: (res) => {
                // console.log(res)
                setTimeout(() => {
                    setIsLoading(false);
                    window.location.reload();
                }, 1000);
            }
        })
    }

    return <>
        <Card padding={2}>
            <CardHeader>
                <Typography variant="h5" color="text.secondary">
                    {data.identity_status}
                </Typography>
            </CardHeader>
            <CardContent>
                <Grid container>
                    <Grid item sm={12} md={5} lg={4}>
                        <Box sx={{ padding: '0 2rem 1rem 1rem' }}>
                            <FormControl sx={{ m: 1 }} fullWidth>
                                <TextField id="fullname" name="fullname" label="Nombre Completo" variant="outlined" onChange={(e) => handleChange(e.target.value, "fullname")} defaultValue={form.fullname} />
                            </FormControl>
                            <FormControl sx={{ m: 1 }} fullWidth>
                                <TextField id="birth" name="birth" label="Fecha Nacimiento" variant="outlined" onChange={(e) => handleChange(e.target.value, "birth")} defaultValue={form.birth.split(" 00:")[0]} placeholder="YYYY-MM-DD" />
                            </FormControl>
                            <FormControl sx={{ m: 1 }} fullWidth>
                                <TextField id="document_number" name="document_number" label="Número de documento" variant="outlined" onChange={(e) => handleChange(e.target.value, "document_number")} defaultValue={form.document_number} />
                            </FormControl>

                            {data.phone_number &&
                                <FormControl sx={{ m: 1 }} fullWidth>
                                    <TextField id="phone_number" name="phone_number" label="Número de teléfono" variant="outlined" defaultValue={data.phone_number} />
                                </FormControl>}
                            {data.email_address &&
                                <FormControl sx={{ m: 1 }} fullWidth>
                                    <TextField id="email_address" name="email_address" label="E-mail" variant="outlined" defaultValue={data.email_address} />
                                </FormControl>}
                            {(data.estimated_age || data.estimated_age >= 1) && (
                                <FormControl sx={{ m: 1 }} fullWidth>
                                    <TextField id="estimated_age" name="estimated_age" label="Edad Est. Selfie" variant="outlined" defaultValue={data.estimated_age} />
                                </FormControl>)}
                            {data.dni_number &&
                                <FormControl sx={{ m: 1 }} fullWidth>
                                    <TextField id="dni_number" name="dni_number" label="DNI" variant="outlined" defaultValue={data.dni_number} />
                                </FormControl>}

                            <FormControl sx={{ m: 1 }} fullWidth>
                                <InputLabel id="error_code">Estado</InputLabel>
                                <Select label="Estado" name="error_code" onChange={e => changeSelectValue(e)} value={form.error_code} defaultValue={form.error_code}>
                                    <MenuItem value="1000 - OK">Verificado y aprobado</MenuItem>
                                    <MenuItem value="1001 - Video tomado con poca luz (oscuridad)">Video tomado con poca luz (oscuridad)</MenuItem>
                                    <MenuItem value="1002 - Video tomado con poca luz (oscuridad)">Video tomado a contra luz (mucho brillo)</MenuItem>
                                    <MenuItem value="1003 - Video distorsionado, se movió la cámara al momento de tomar el video">Video distorsionado, se movió la cámara al momento de tomar el video</MenuItem>
                                    <MenuItem value="1004 - Video tomado a mucha distancia">Video tomado a mucha distancia</MenuItem>
                                    <MenuItem value="1005 - Video tomado a poca distancia">Video tomado a poca distancia</MenuItem>
                                    <MenuItem value="1006 - Elementos que impiden ver el rostro (gafas oscuras, tapabocas, gorra, máscara)">Elementos que impiden ver el rostro (gafas oscuras, tapabocas, gorra, máscara)</MenuItem>
                                    <MenuItem value="1007 - Aparecen dos o mas rostros en la captura del video">Aparecen dos o mas rostros en la captura del video</MenuItem>
                                    <MenuItem value="1008 - La persona del video selfie no corresponde con la fotografía del documento">La persona del video selfie no corresponde con la fotografía del documento</MenuItem>
                                    <MenuItem value="1009 - Fotografía tomada en pobres condiciones de iluminación (poca luz)">Fotografía tomada en pobres condiciones de iluminación (poca luz)</MenuItem>
                                    <MenuItem value="1010 - Fotografía tomada con mucha luz (Flash sobre la foto)">Fotografía tomada con mucha luz (Flash sobre la foto)</MenuItem>
                                    <MenuItem value="1011 - >Los hologramas impiden visualizar la fotografía del documento">Los hologramas impiden visualizar la fotografía del documento</MenuItem>
                                    <MenuItem value="1012 - Fotografía distorsionada o borrosa, se movió la cámara al momento de la captura del documento">Fotografía distorsionada o borrosa, se movió la cámara al momento de la captura del documento</MenuItem>
                                    <MenuItem value="1013 - Fotografía incompleta (Se visualiza parte del documento no su totalidad)">Fotografía incompleta (Se visualiza parte del documento no su totalidad)</MenuItem>
                                    <MenuItem value="1014 - No suministra la fotografía correcta">No suministra la fotografía correcta</MenuItem>
                                    <MenuItem value="1015 - Se suministró fotocopia del documento">Se suministró fotocopia del documento</MenuItem>
                                    <MenuItem value="1016 - Documento laminado o en funda plástica">Documento laminado o en funda plástica</MenuItem>
                                    <MenuItem value="1017 - No es posible emitir concepto">No es posible emitir concepto</MenuItem>
                                    <MenuItem value="1018 - Características de seguridad del documento impiden su aprobación">Características de seguridad del documento impiden su aprobación</MenuItem>
                                    <MenuItem value="1019 - La persona no realizó los dos círculos con la cabeza">La persona no realizó los dos círculos con la cabeza</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1 }} fullWidth>
                                <InputLabel id="status">Status</InputLabel>
                                <Select label="Status" name="status" onChange={e => changeSelectValue(e)} value={form.status} defaultValue={form.status}>
                                    <MenuItem value="1">Exitoso</MenuItem>
                                    <MenuItem value="2">Exitoso parcial</MenuItem>
                                    <MenuItem value="3">Repetir validación</MenuItem>
                                    <MenuItem value="4">Negado</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1 }} fullWidth>
                                <TextField id="created_at" name="created_at" label="Fecha de verificación" variant="outlined" defaultValue={data?.created_at && new Date(data.created_at).toLocaleDateString(undefined, {
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    second: 'numeric'
                                })}
                                    InputProps={{
                                        readOnly: true,
                                    }} />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={7} lg={8}>
                        <Box sx={{ padding: '0 2rem 1rem 1rem' }}>
                            <div className="columns">
                                {form.verification.alteration != null ? (
                                    <div className="column">
                                        <div className="box" style={{ cursor: "pointer" }} onClick={e => (handleChangeCheckBox(e, "alteration"))}>
                                            <label htmlFor="alteration">
                                                <div className="level" style={{ cursor: "pointer" }}>

                                                    <div className="level-left">
                                                        <div className="level-item">
                                                            <div>
                                                                <input
                                                                    name="alteration"
                                                                    type="checkbox"
                                                                    style={{ display: "none" }}
                                                                    defaultValue={form.verification.alteration}
                                                                    onChange={e => (handleChangeCheckBox(e, "alteration"))}
                                                                    defaultChecked={form.verification.alteration} />
                                                                <Typography><strong>Detección de alteraciones</strong></Typography>
                                                                {form.verification.alteration ? (
                                                                    <Typography className="has-text-success">No alterado</Typography>
                                                                ) : (
                                                                    <Typography className="has-text-danger">Alterado</Typography>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/*  */}
                                                    </div>
                                                    <div className="level-rigth mr-3">
                                                        <div className="level-item">
                                                            {form.verification.alteration ? (
                                                                <EditIcon className="icon has-text-success" />
                                                            ) : (
                                                                <EditIcon className="icon has-text-danger" />
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                ) : <div className="column">
                                    <div className="box" style={{ cursor: "pointer" }} onClick={e => (handleChangeCheckBoxFromNull(e, "alteration"))}>
                                        <label htmlFor="alteration">
                                            <div className="level" style={{ cursor: "pointer" }}>

                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <input
                                                                name="alteration"
                                                                type="checkbox"
                                                                style={{ display: "none" }}
                                                                defaultValue={form.verification.alteration}
                                                                onChange={e => (handleChangeCheckBox(e, "alteration"))}
                                                                defaultChecked={form.verification.alteration} />
                                                            <Typography><strong>Detección de alteraciones</strong></Typography>
                                                            <Typography className="has-text-grey">No alterado</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        <EditIcon className="icon has-text-gray" />
                                                    </div>
                                                </div>

                                            </div>
                                        </label>
                                    </div>
                                </div>}
                                {form.verification.face_match != null ? (
                                    <div className="column">
                                        <div className="box" style={{ cursor: "pointer" }} onClick={e => (handleChangeCheckBox(e, "face_match"))}>
                                            <div className="level" style={{ cursor: "pointer" }}>
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Facematch</strong></Typography>
                                                            {form.verification.face_match ? (
                                                                <div>
                                                                    <Typography className="has-text-success">Coincide: {data.face_match_score ? (<strong>{data.face_match_score} % </strong>) : null}</Typography>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <Typography className="has-text-danger">No coincide: {data.face_match_score ? (<strong>{data.face_match_score} % </strong>) : null}</Typography>
                                                                </div>

                                                            )}
                                                        </div>
                                                    </div>
                                                    {/*  */}
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {form.verification.face_match ? (
                                                            <SearchPeopleIcon className="icon has-text-success" />
                                                        ) : (
                                                            <SearchPeopleIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="column">
                                        <div className="box" style={{ cursor: "pointer" }} onClick={e => (handleChangeCheckBoxFromNull(e, "face_match"))}>
                                            <div className="level" style={{ cursor: "pointer" }}>
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Facematch</strong></Typography>
                                                            <Typography className="has-text-grey"></Typography>
                                                        </div>
                                                    </div>
                                                    {/*  */}
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        <SearchPeopleIcon className="icon has-text-gray" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="columns">
                                {form.verification.template != null ? (
                                    <div className="column">
                                        <div className="box" style={{ cursor: "pointer" }} onClick={e => (handleChangeCheckBox(e, "template"))}>
                                            <div className="level" style={{ cursor: "pointer" }}>
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Rev. tipo de documento</strong></Typography>
                                                            {form.verification.template ? (
                                                                <Typography className="has-text-success">Coincide</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">No Coincide</Typography>
                                                            )}
                                                        </div>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {form.verification.template ? (
                                                            <CharacterAuthorizeIcon className="icon has-text-success is-size-4" />
                                                        ) : (
                                                            <CharacterAuthorizeIcon className="icon has-text-danger is-size-4" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="column">
                                        <div className="box" style={{ cursor: "pointer" }} onClick={e => (handleChangeCheckBoxFromNull(e, "template"))}>
                                            <div className="level" style={{ cursor: "pointer" }}>
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Revisión de tipo de documento</strong></Typography>
                                                            <Typography className="has-text-grey">Coincide</Typography>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        <CharacterAuthorizeIcon className="icon has-text-gray is-size-4" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {form.verification.watch_list != null ? (
                                    <div className="column">
                                        <div className="box" style={{ cursor: "pointer" }} onClick={e => (handleChangeCheckBox(e, "watch_list"))}>
                                            <div className="level" style={{ cursor: "pointer" }}>
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Listas Negras</strong></Typography>
                                                            {(form.verification.watch_list || form.fullname == null) ? (
                                                                <Typography className="has-text-success">No encontrado</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">Encontrado</Typography>
                                                            )}
                                                        </div>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {(form.verification.watch_list || form.fullname == null) ? (
                                                            <PageIcon className="icon has-text-success" />
                                                        ) : (
                                                            <PageIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (<div className="column">
                                    <div className="box" style={{ cursor: "pointer" }} onClick={e => (handleChangeCheckBoxFromNull(e, "watch_list"))}>
                                        <div className="level" style={{ cursor: "pointer" }}>
                                            <div className="level-left">
                                                <div className="level-item">
                                                    <div>
                                                        <Typography><strong>Listas Negras</strong></Typography>
                                                        <Typography className="has-text-grey">No encontrado</Typography>
                                                    </div>
                                                    {/*  */}
                                                </div>
                                            </div>
                                            <div className="level-rigth mr-3">
                                                <div className="level-item">
                                                    <PageIcon className="icon has-text-gray" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                                {data.verification?.liveness !== null &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Liveness</strong></Typography>
                                                            {data.verification?.liveness ? (
                                                                <Typography className="has-text-success">Pasó</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">No pasó</Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.verification?.liveness ? (
                                                            <PersonIcon className="icon has-text-success" />
                                                        ) : (
                                                            <PersonOffIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="columns">
                                {data.verification.estimated_age != null ? (
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left mr-2">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Edad Est. Selfie</strong></Typography>
                                                            {data.verification.estimated_age ? (
                                                                <Typography className="has-text-success">Coincide</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">No Coincide</Typography>
                                                            )}
                                                        </div>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.verification.estimated_age ? (
                                                            <CharacterAuthorizeIcon className="icon has-text-success is-size-4" />
                                                        ) : (
                                                            <CharacterAuthorizeIcon className="icon has-text-danger is-size-4" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {data.verification.email_deliverable != null ? (
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Revisión de Email Entregable</strong></Typography>
                                                            {data.verification.email_deliverable ? (
                                                                <Typography className="has-text-success">Entregable</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">No Entregable</Typography>
                                                            )}
                                                        </div>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.verification.email_deliverable ? (
                                                            <CharacterAuthorizeIcon className="icon has-text-success is-size-4" />
                                                        ) : (
                                                            <CharacterAuthorizeIcon className="icon has-text-danger is-size-4" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ("")}
                                {data.verification.email_safe != null ? (
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Revisión de Email Seguro</strong></Typography>
                                                            {data.verification.email_safe ? (
                                                                <Typography className="has-text-success">Seguro</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">No Seguro</Typography>
                                                            )}
                                                        </div>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.verification.email_safe ? (
                                                            <CharacterAuthorizeIcon className="icon has-text-success is-size-4" />
                                                        ) : (
                                                            <CharacterAuthorizeIcon className="icon has-text-danger is-size-4" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ("")}
                                {data.verification.one_to_many_result != null ? (
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>1 a N</strong></Typography>
                                                            {data.verification.one_to_many_result ? (
                                                                <Typography className="has-text-success">Seguro</Typography>
                                                            ) : (
                                                                <div>
                                                                    <a target="_blank" rel="noopener noreferrer" className="button is-danger is-small mt-2" href={urlBase + "verification/" + data.one_to_many_user_id_matched}>Coincidencia Encontrada</a>
                                                                </div>

                                                            )}
                                                        </div>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.verification.one_to_many_result ? (
                                                            <SearchPeopleIcon className="icon has-text-success" />
                                                        ) : (
                                                            <SearchPeopleIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ("")}

                                {data?.phone_score_validation &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Riesgo Teléfono</strong></Typography>
                                                            <Typography className={`riesgo-${riesgo}`}>
                                                                {riesgo}
                                                            </Typography>
                                                        </div>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        <EditIcon className={`icon riesgo-${riesgo} text-right`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                {data?.ocr_validation?.alteraciones?.alt_face_age && data?.ocr_validation?.alteraciones?.alt_face_age.result !== null &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Edad Foto Cédula</strong></Typography>
                                                            {data?.ocr_validation?.alteraciones?.alt_face_age.result ? (
                                                                <Typography className="has-text-success">Pasó - {data?.ocr_validation?.alteraciones?.alt_face_age?.compared_age}</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">No pasó - {data?.ocr_validation?.alteraciones?.alt_face_age?.compared_age}</Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data?.blacklist?.result ? (
                                                            <PersonIcon className="icon has-text-success" />
                                                        ) : (
                                                            <PersonOffIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {data?.ocr_validation?.age_range &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Edad Est. Foto Cédula</strong></Typography>
                                                            {data?.ocr_validation?.age_range?.low && data?.ocr_validation?.age_range?.high && (
                                                                <Typography>
                                                                    Min: {data?.ocr_validation?.age_range?.low} - Max: {data?.ocr_validation?.age_range?.high}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">

                                                        {/* <PersonIcon className="icon has-text-success" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="columns">
                                {data.ocr_validation?.alteraciones?.alt_registrador &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Registrador</strong></Typography>
                                                            {data.ocr_validation.alteraciones.alt_registrador ? (
                                                                <Typography className="has-text-success">No alterado</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">Alterado</Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.ocr_validation.alteraciones.alt_registrador ? (
                                                            <EditIcon className="icon has-text-success" />
                                                        ) : (
                                                            <EditIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {data.ocr_validation?.alteraciones?.alt_sexo &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Sexo</strong></Typography>
                                                            {data.ocr_validation.alteraciones.alt_sexo ? (
                                                                <Typography className="has-text-success">No Alterado</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">Alterado</Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.ocr_validation.alteraciones.alt_sexo ? (
                                                            <EditIcon className="icon has-text-success" />
                                                        ) : (
                                                            <EditIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {data.ocr_validation?.alteraciones?.alt_dates &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Fechas</strong></Typography>
                                                            {data.ocr_validation.alteraciones.alt_dates ? (
                                                                <Typography className="has-text-success">No alterado</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">Alterado</Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.ocr_validation.alteraciones.alt_dates ? (
                                                            <EditIcon className="icon has-text-success" />
                                                        ) : (
                                                            <EditIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {data.ocr_validation?.alteraciones?.alt_mayor_de_edad &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Mayor de edad</strong></Typography>
                                                            {data.ocr_validation.alteraciones.alt_mayor_de_edad ? (
                                                                <Typography className="has-text-success">No alterado</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">Alterado</Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data.ocr_validation.alteraciones.alt_mayor_de_edad ? (
                                                            <EditIcon className="icon has-text-success" />
                                                        ) : (
                                                            <EditIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {data?.blacklist && data.blacklist?.result !== null &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Blacklist Facial</strong></Typography>
                                                            {data?.blacklist?.result ? (
                                                                <Typography className="has-text-success">Pasó</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">No pasó</Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data?.blacklist?.result ? (
                                                            <PersonIcon className="icon has-text-success" />
                                                        ) : (
                                                            <PersonOffIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {data?.brasil && data.brasil?.biometria_face !== null &&
                                    <div className="column">
                                        <div className="box">
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">
                                                        <div>
                                                            <Typography><strong>Serpro Face</strong></Typography>
                                                            {data?.brasil?.biometria_face_score > 0.8 ? (
                                                                <Typography className="has-text-success">Coincide {data.brasil.biometria_face_score ? (<strong>{`${Math.round((data.brasil.biometria_face_score * 100) * 100) / 100})`} % </strong>) : null}</Typography>
                                                            ) : (
                                                                <Typography className="has-text-danger">No coincide</Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="level-rigth mr-3">
                                                    <div className="level-item">
                                                        {data?.brasil?.biometria_face ? (
                                                            <PersonIcon className="icon has-text-success" />
                                                        ) : (
                                                            <PersonOffIcon className="icon has-text-danger" />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Box>
                    </Grid>
                </Grid>

                <Box component="div" sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center' }} data-html2canvas-ignore>
                    <Button disabled={!canUseTelesign || isLoading} variant="contained" endIcon={<SmartphoneIcon />} onClick={handleTelcoRequest}>Telco</Button>
                    <Button disabled={!canUseUbicaPlus || isLoading} variant="contained" endIcon={<RecentActorsIcon />} onClick={handleBureauRequest}>Bureau</Button>
                    <Button disabled={!canSendForm || isLoading} variant="contained" endIcon={<SendIcon />} onClick={submitForm}>Editar</Button>
                </Box>
            </CardContent>
        </Card>
    </>;
}

export default Reviewer