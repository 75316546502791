import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function VerificationsTable({ data, tokenInfo, detailRedirect, count, page, setPage, isLoading }) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer component={Paper} sx={{ position: 'relative' }}>
      {isLoading && <Backdrop
        sx={{ position: 'absolute', opacity: 0.5, color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}
      <Table sx={{ minWidth: 500 }} aria-label="simple table">        
        <TableHead>
          <TableRow>
            <TableCell style={{ float: "left" }}><abbr title="Created at">Creación</abbr></TableCell>
            <TableCell><abbr title="Name">Nombre</abbr></TableCell>
            <TableCell><abbr title="Type">Tipo</abbr></TableCell>
            <TableCell><abbr title="Document">Documento</abbr></TableCell>
            <TableCell><abbr title="State">Estado</abbr></TableCell>
            <TableCell><abbr title="Alteration">Alteration</abbr></TableCell>
            <TableCell><abbr title="FaceMatch">Facematch</abbr></TableCell>
            <TableCell><abbr title="Template">Template</abbr></TableCell>
            <TableCell><abbr title="WatchLists">Watchlists</abbr></TableCell>
            {tokenInfo?.is_reviewer && <TableCell><abbr title="1toN">1:N</abbr></TableCell>}
            {tokenInfo?.is_reviewer && <TableCell><abbr title="EstimatedAge">Age</abbr></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody style={{ overflowY: "scroll", height: "660px" }}>
          
          {data.map(el =>
            <TableRow key={el.id} style={{ cursor: 'pointer' }} onClick={e => detailRedirect(el.user_id)}>
              <TableCell>{new Date(Date.parse(el.created_at)).toLocaleDateString("es-CO")}</TableCell>
              <TableCell>{el.fullName}</TableCell>
              <TableCell>
                {el.documentType === 'national-id' ? 'NI' : el.documentType === 'passport' ? 'PP' : el.documentType === 'driver-license' ? 'DL' : 'NN'}
              </TableCell>
              <TableCell>{el.documentID}</TableCell>
              <TableCell>
                {el.verification_status === "completed" ? ' ✅' : el.verification_status === "pending" ? '⚠️' : '🚨'}
              </TableCell>

              <TableCell>{el.verification.alteration == null ? '' : el.verification.alteration ? '✅' : '❌'}</TableCell>
              <TableCell>{el.verification.facematch == null ? '' : el.verification.facematch ? '✅' : '❌'}</TableCell>
              <TableCell>{el.verification.template == null ? '' : el.verification.template ? '✅' : '❌'}</TableCell>
              <TableCell> {el.verification.watchlists == null ? '' : el.verification.watchlists ? '✅' : '❌'}</TableCell>
              {tokenInfo?.is_reviewer &&
                <TableCell>
                  {el.verification.one_to_many_result == null ? '' : el.verification.one_to_many_result ? '✅' : '❌'}
                </TableCell>
              }
              {tokenInfo?.is_reviewer &&
                <TableCell>
                  {el.verification.estimated_age == null ? '' : el.verification.estimated_age ? '✅' : '❌'}
                </TableCell>
              }
            </TableRow>)
          }
        </TableBody>
      </Table>
      { count > 0 && (<TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={count}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      //onRowsPerPageChange={handleChangeRowsPerPage}
      />)}
      
    </TableContainer>
  );
}
