import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import LocalStorageService from '../libs/LocalStorageService';
import { Loader } from 'rsuite';
import VerificationInfo from "../components/VerificationInfo";
import ErrorComponent from '../components/ErrorComponent';

const Verification = () => {
  const [data, setData] = useState({});
  const [hasError, setHasError] = useState(false);
  const { id } = useParams();
  const APIURL = "https://becomedigital-api.azurewebsites.net/api/v1";

  useEffect(() => {
    const token = LocalStorageService.getAccessToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
    };
    axios.get(`${APIURL}/identity/${id}`, config).then((result) => {
      if (result.status === 200 || result.status === 202) {
        setData(result.data);
        setHasError(false);
      } else {
        setHasError(true);
      }
    }).catch((e) => {
      console.log(e);
      setHasError(true);
    });

  }, [id]);

  return (<main className="main">
    {hasError ? <ErrorComponent /> :
      Object.keys(data).length === 0 ?
        <div className="bcLoader">
          <Loader size="lg" content="" />
        </div> :
        <VerificationInfo data={data} setData={setData} id={id} />
    }
  </main>)
}

export default Verification;