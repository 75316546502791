import React, { Fragment } from 'react';
import Content from '../components/Content';
import "./Dashboard.css";
import Navbar from '../components/Navbar';
import { Container } from '@mui/material';

const Dashboard = () => {
  return (<Fragment>
    <div className="Dashboard">
      <Navbar className="no-print" />
      <Container maxWidth="xl" >
        <Content />
    </Container>
    </div>
  </Fragment>);
}

export default Dashboard;