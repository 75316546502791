import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from "react-router-dom";

import Verifications from "../content/Verifications";
import Profile from "../content/Profile";
import Verification from "../content/Verification";
import NotFound from "../pages/NotFound";
import Contracts from "../content/Contracts";
 
const Content = () => {
  return (
    <Router basename="dashboard">
    <Switch>
      <Route exact path="/" component={Verifications} />
      <Route exact path="/verification/:id" component={Verification} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/contracts" component={Contracts} />
      {/* AQUÍ VAN LAS DEMÁS RUTAS!
       */}
      <Route component={NotFound} />
    </Switch>
  </Router>
  );
};

export default Content;
